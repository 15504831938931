// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import VerifyEmail from './components/VerifyEmail';
import Welcome from './components/Welcome';
import TopNavigation from './layouts/TopNavigation';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import BackendRegistrationRetry from './components/BackendRegistrationRetry';

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();
  
  if (!currentUser) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <TopNavigation />
      {children}
    </>
  );
}

function PublicRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/welcome" /> : children;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <BackendRegistrationRetry />
        <Routes>
          <Route path="/" element={<Navigate to="/signin" />} />
          
          {/* Public Routes */}
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-email"
            element={
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
            }
          />

          {/* Protected Routes with Navigation */}
          <Route
            path="/welcome"
            element={
              <ProtectedRoute>
                <Welcome />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;