import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PenSquare } from 'lucide-react';
import styles from './Welcome.module.css';

function Welcome() {
  const { currentUser } = useAuth();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Welcome, {currentUser.displayName || currentUser.email}!
        </h1>
      </div>
      <p className={styles.subtitle}>You are now signed in.</p>

      <button className={styles.composeButton}>
        <PenSquare size={16} />
        <span>Compose</span>
      </button>
    </div>
  );
}

export default Welcome;