import React from 'react';
import { Link } from 'react-router-dom';
import styles from './VerifyEmail.module.css';

function VerifyEmail() {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.h1}>Verify Your Email</h1>
        <p className={styles.message}>
          We've sent a verification email to your address. Please check your inbox and click on the verification link to complete your registration.
        </p>
        <p className={styles.subMessage}>
          If you don't see the email, please check your spam folder.
        </p>
        <Link to="/signin" className={styles.link}>
          Return to Sign In
        </Link>
      </div>
    </div>
  );
}

export default VerifyEmail;