import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signUpWithEmailAndPassword, signInWithGoogle, signInWithFacebook } from '../services/firebase';
import { FaGoogle, FaFacebook, FaEye, FaEyeSlash } from 'react-icons/fa';
import styles from './SignUp.module.css';
import { useAuth } from '../contexts/AuthContext';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[#@]/, 'Password must contain at least one # or @')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[A-Z]/, 'Password must contain at least one capital letter')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const user = await signUpWithEmailAndPassword(values.email, values.password);
      setCurrentUser(user);
      navigate('/welcome');
    } catch (error) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSocialSignUp = async (signUpMethod) => {
    try {
      await signUpMethod();
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  //       <div className="input-group">
  //          <label>Phone number</label>
  //          <div className="phone-input">
  //            <select className="country-code">
  //            <option value="US">US +1</option>
  //              // Add more country codes as needed
  //            </select>
  //            <input
  //              type="tel"
  //              name="phoneNumber"
  //              value={formData.phoneNumber}
  //              onChange={handleChange}
  //              placeholder="Phone number"
  //              className="singup-input"
  //            />
  //          </div>
  //          <span className="optional">Optional</span>
  //        </div>


  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.h1}>Create your account</h1>
        <p className={styles.subtitle}>Welcome! Please fill in the details to get started.</p>

        {error && <div className={styles.error_message}>{error}</div>}

        <div className={styles.social_buttons}>
          <button
            className={`${styles.social_button} ${styles.facebook}`}
            onClick={() => handleSocialSignUp(signInWithFacebook)}
          >
            <FaFacebook /> Facebook
          </button>
          <button
            className={`${styles.social_button} ${styles.google}`}
            onClick={() => handleSocialSignUp(signInWithGoogle)}
          >
            <FaGoogle /> Google
          </button>
        </div>

        <div className={styles.divider}>or</div>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form_container}>
              <div className={styles.name_fields}>
                <div className={styles.input_group}>
                  <label htmlFor="firstName">First name</label>
                  <Field name="firstName" type="text" className={styles.input} />
                  <ErrorMessage name="firstName" component="div" className={styles.error} />
                </div>

                <div className={styles.input_group}>
                  <label htmlFor="lastName">Last name</label>
                  <Field name="lastName" type="text" className={styles.input} />
                  <ErrorMessage name="lastName" component="div" className={styles.error} />
                </div>
              </div>

              <div className={styles.input_group}>
                <label htmlFor="email">Email address</label>
                <Field name="email" type="email" className={styles.input} />
                <ErrorMessage name="email" component="div" className={styles.error} />
              </div>

              <div className={styles.input_group}>
                <label htmlFor="password">Password</label>
                <div className={styles.password_input}>
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className={styles.input}
                  />
                  <button
                    type="button"
                    className={styles.toggle_password}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <ErrorMessage name="password" component="div" className={styles.error} />
              </div>

              <div className={styles.input_group}>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <div className={styles.password_input}>
                  <Field
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    className={styles.input}
                  />
                  <button
                    type="button"
                    className={styles.toggle_password}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <ErrorMessage name="confirmPassword" component="div" className={styles.error} />
              </div>

              <button 
                type="submit" 
                className={styles.submit_button}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creating account...' : 'Continue'}
              </button>
            </Form>
          )}
        </Formik>
        <p className={styles.signin_link}>
          Already have an account? <Link to="/signin">Sign in</Link>
        </p>
      </div>
    </div>
  );
}

export default SignUp;