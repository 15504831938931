import axios from 'axios';
import { getAuth } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api';

export const registerUserInBackend = async (user) => {
  try {
    // Get the current Firebase user
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    // Get the Firebase ID token
    const idToken = await currentUser.getIdToken(true);

    // Set up the headers with the Firebase token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    };

    const response = await axios.post(`${API_BASE_URL}/users/`, {
      firebase_uid: user.uid,
      email: user.email,
      full_name: user.displayName || null,
      phone_number: user.phoneNumber || null,
      profile_picture_url: user.photoURL || null,
      is_active: true,
      preferences: {},
      metadata: {},
      default_countdown_days: 7
    }, { headers });

    return response.data;
  } catch (error) {
    console.error('Error registering user in backend:', error);
    throw error;
  }
};