import React, { useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmail, signInWithGoogle, signInWithFacebook } from '../services/firebase';
import { registerUserInBackend } from '../services/neojiva_api';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import styles from './SignIn.module.css';
import { useAuth } from '../contexts/AuthContext';
import ErrorMessage from './ErrorMessage';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSignInSuccess = useCallback(async (user) => {
    try {
      await registerUserInBackend(user);
    } catch (error) {
      console.error('Failed to register user in backend:', error);
      // Store this information to retry later
      localStorage.setItem('pendingBackendRegistration', 'true');
    }
    navigate('/welcome');
  }, [navigate]);

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const user = await signInWithEmail(email, password);
      await handleSignInSuccess(user);
    } catch (error) {
      setError('Failed to sign in: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialSignIn = async (signInMethod) => {
    setLoading(true);
    setError('');
    try {
      const user = await signInMethod();
      await handleSignInSuccess(user);
    } catch (error) {
      setError('Failed to sign in: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle case where user is already signed in
  React.useEffect(() => {
    if (currentUser && !loading) {
      handleSignInSuccess(currentUser);
    }
  }, [currentUser, loading, handleSignInSuccess]);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.h1}>Welcome to NeoJiva</h1>
        <p className={styles.subtitle}>Please sign in to continue</p>
        
        {error && <ErrorMessage message={error} />}
        
        <div className={styles.social_buttons}>
          <button 
            className={`${styles.social_button} ${styles.facebook}`}
            onClick={() => handleSocialSignIn(signInWithFacebook)}
            disabled={loading}
            aria-label="Sign in with Facebook"
          >
            <FaFacebook /> Facebook
          </button>
          <button 
            className={`${styles.social_button} ${styles.google}`}
            onClick={() => handleSocialSignIn(signInWithGoogle)}
            disabled={loading}
            aria-label="Sign in with Google"
          >
            <FaGoogle /> Google
          </button>
        </div>
        <div className={styles.divider}>or</div>
        <form onSubmit={handleEmailSignIn}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            aria-label="Email"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            aria-label="Password"
          />
          <button type="submit" className={styles.login_button} disabled={loading}>
            {loading ? 'Signing in...' : 'Sign in with Email'}
          </button>
        </form> 
        <p className={styles.signup_toggle}>
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
      </div>
    </div>
  );
}

export default SignIn;