// layouts/TopNavigation.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Search, Settings, LogOut } from 'lucide-react';
import CompactCountdownTimer from '../components/CompactCountdownTimer';
import { useAuth } from '../contexts/AuthContext';
import { signOutUser } from '../services/firebase';
import { useNavigate } from 'react-router-dom';
import styles from './TopNavigation.module.css';

const TopNavigation = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileRef = useRef(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef]);

  const handleSignOut = async () => {
    try {
      await signOutUser();
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const firstLetter = currentUser?.displayName?.[0] || currentUser?.email?.[0] || 'U';

  const handleProfileClick = (e) => {
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        {/* Left side */}
        <div className={styles.logo}>
          <span className={styles.logoText}>NJ</span>
        </div>

        {/* Center */}
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
          />
          <Search className={styles.searchIcon} />
        </div>

        {/* Right side */}
        <div className={styles.rightSection}>
          <CompactCountdownTimer />
          <div className={styles.profileContainer} ref={profileRef}>
            <button
              onClick={handleProfileClick}
              className={styles.profileButton}
              type="button"
            >
              {firstLetter}
            </button>

            {showProfileMenu && (
              <div className={styles.profileDropdown}>
                <div className={styles.userInfo}>
                  <p className={styles.userName}>
                    {currentUser?.displayName || 'User'}
                  </p>
                  <p className={styles.userEmail}>{currentUser?.email}</p>
                </div>
                <button
                  onClick={() => {/* Handle manage account */}}
                  className={styles.menuItem}
                  type="button"
                >
                  <Settings size={16} />
                  <span>Manage account</span>
                </button>
                <button
                  onClick={handleSignOut}
                  className={styles.menuItem}
                  type="button"
                >
                  <LogOut size={16} />
                  <span>Sign out</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavigation;