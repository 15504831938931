import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { registerUserInBackend } from '../services/neojiva_api';

function BackendRegistrationRetry() {
  const { currentUser } = useAuth();

  useEffect(() => {
    const retryBackendRegistration = async () => {
      if (localStorage.getItem('pendingBackendRegistration') === 'true' && currentUser) {
        try {
          await registerUserInBackend(currentUser);
          localStorage.removeItem('pendingBackendRegistration');
        } catch (error) {
          console.error('Failed to register user in backend:', error);
        }
      }
    };

    retryBackendRegistration();
  }, [currentUser]);

  return null; // This component doesn't render anything
}

export default BackendRegistrationRetry;