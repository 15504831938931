import React, { useState, useEffect } from 'react';
import { Clock, RotateCcw, Play, Pause } from 'lucide-react';
import styles from './CompactCountdownTimer.module.css';

const CompactCountdownTimer = () => {
  const [initialDays, setInitialDays] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [inputDays, setInputDays] = useState('');

  useEffect(() => {
    let interval;
    if (isRunning && remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1000) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, remainingTime]);

  const startTimer = (days) => {
    const milliseconds = days * 24 * 60 * 60 * 1000;
    setInitialDays(days);
    setRemainingTime(milliseconds);
    setIsRunning(true);
    // Instead of clearing input, keep the value
    setInputDays(days.toString());
  };

  const restartTimer = () => {
    if (initialDays) {
      startTimer(initialDays);
    }
  };

  const formatTime = (ms) => {
    if (!ms) return '0d 0h 0m 0s';
    
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);

    // Show only seconds if days=0, hours=0, and minutes<10
    if (days === 0 && hours === 0 && minutes < 10) {
      return `${hours}h ${minutes}m ${seconds}s`;
    }

    return `${days}d ${hours}h ${minutes}m`;
  };

  const handleSetTimer = (e) => {
    e.stopPropagation();
    const days = parseInt(inputDays) || 2;
    const validDays = Math.min(30, Math.max(2, days));
    startTimer(validDays);
  };

  return (
    <div className={styles.timerContainer}>
      <button
        className={styles.timerButton}
        onClick={() => setShowSettings(!showSettings)}
        type="button"
      >
        <Clock className={styles.timerIcon} />
        <span>
          {!remainingTime ? "Start Countdown" : formatTime(remainingTime)}
        </span>
      </button>

      {showSettings && (
        <div className={styles.timerDropdown}>
          <div className={styles.timerContent}>
            {/* Timer Settings Section */}
            <div className={styles.timerSettings}>
              <label className={styles.timerLabel}>
                Set Timer Duration (2-30 days)
              </label>
              <div className={styles.inputGroup}>
                <input
                  type="number"
                  min="2"
                  max="30"
                  value={inputDays}
                  onChange={(e) => setInputDays(e.target.value)}
                  className={styles.timerInput}
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Enter days"
                />
                <button
                  onClick={handleSetTimer}
                  className={styles.setButton}
                  type="button"
                >
                  Set
                </button>
              </div>
            </div>

            {/* Timer Controls Section */}
            {remainingTime !== null && (
              <div className={styles.timerControls}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRunning(!isRunning);
                  }}
                  className={`${styles.controlButton} ${styles.playButton}`}
                  type="button"
                >
                  {isRunning ? (
                    <>
                      <Pause className={styles.buttonIcon} />
                      <span>Pause</span>
                    </>
                  ) : (
                    <>
                      <Play className={styles.buttonIcon} />
                      <span>Start</span>
                    </>
                  )}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    restartTimer();
                  }}
                  className={`${styles.controlButton} ${styles.restartButton}`}
                  type="button"
                >
                  <RotateCcw className={styles.buttonIcon} />
                  <span>Restart</span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompactCountdownTimer;